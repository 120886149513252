.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  .modal-overlay.show {
    opacity: 1;
    pointer-events: auto;
  }
  
  .modal {
    background: #282C34;
    color: #A0ABCB;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 90%;
    width: 400px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    width: 90%;
  }
  
  h2 {
    margin: 0 0 10px;
    font-size: 24px;
    color: #5c81ee;
  }
  
  p {
    margin: 0;
    font-size: 18px;
    color: #3d4c79;
  }
  
  .return-button {
    background-color: #B6330A;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .return-button:hover {
    background-color: #e9957b;
  }
  