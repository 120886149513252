.project-card {
  position: relative;
  background-color: #111214;
  color: #282C34;
  padding: 40px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.2em;
  transition: transform 0.3s, box-shadow 0.3s;
  min-width: 400px;
  height: 240px;
  border: 2px solid #A0ABCB;
  overflow: hidden;
}

.project-card:hover {
  box-shadow: 0px 4px 0px #A0ABCB;
  transform: translateY(-4px);
}

.card-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 2;
}

.overlay-layer {
  background-color: #A0ABCB;
  z-index: 1;
  height: 78%;
  transition: background-color 0.3s;
}

.overlay-layer.with-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mute-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  transition: background-color 0.3s;
}

.project-card:hover .mute-layer {
  background-color: rgba(0, 0, 0, 0.5);
}

.card-label {
  z-index: 3;
}

.card-label .card-title {
  color: white; /* Ensure the title is visible */
}

.card-text {
  color: #6f99ee;
  text-align: left;
}

.learn-more-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #A0ABCB;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 4;
}

.project-card:hover .learn-more-button {
  opacity: 1;
}
