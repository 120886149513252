@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap');

body {
  overflow-y: auto;
  margin: 0;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  background-color: #282C34;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top-section {
  background-color: #282C34;
  padding: 10px 20px;
  height: 80vh;
  position: relative;
}

.bottom-section {
  background-color: black;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.projects-title {
  color: #A0ABCB;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 48px;
}

.projects-heading {
  color: white;
  font-size: 1.5em;
  margin-bottom: 15px;
  text-align: left;
  margin-top: 20px;
  margin-left: 48px;
}

.navbar {
  width: 100%;
  background-color: #282C34;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  height: 88px;
  z-index: 1000;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-name {
  color: #7E89AA;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  margin-left: 48px;
}

.navbar-home {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  transition: color 0.3s;
  margin-right: 48px;
}

.navbar-home:hover {
  color: #88BFFF;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name-title {
  font-size: 64px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  padding-top: 28vh;
  color: #CBD6F7;
  margin-left: 48px;
}

.job-title {
  font-size: 48px;
  font-weight: 800;
  margin: 5px 0 0 0;
  padding: 0;
  color: #A0ABCB;
  margin-left: 48px;
}

.contact-me-button {
  background-color: #282C34;
  color: #88BFFF;
  border-color: #88BFFF;
  border-width: 1px;
  padding: 16px 24px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 48px;
}

.contact-me-button:hover {
  background-color: #9a9feb46;
}

.projects {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 20px;
  padding-bottom: 10px;
  padding-top: 5px;
  margin-left: 48px;
  margin-top: 5px;
}

body::-webkit-scrollbar,
.bottom-section::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track,
.bottom-section::-webkit-scrollbar-track {
  background: #282C34;
}

body::-webkit-scrollbar-thumb,
.bottom-section::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 10px;
  border: 3px solid #282C34;
}

.projects::-webkit-scrollbar {
  height: 8px;
}

.projects::-webkit-scrollbar-track {
  background: #282C34;
}

.projects::-webkit-scrollbar-thumb {
  background-color: #A0ABCB;
  border-radius: 10px;
  border: 3px solid #282C34;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #111214;
  margin-top: 20px;
}

.footer a {
  display: block;
  color: #A0ABCB;
  text-decoration: none;
  margin: 10px 0;
}

.footer a:hover {
  color: #88BFFF;
}

.footer p {
  margin: 5px 0;
  color: #A0ABCB;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .top-section {
    height: auto;
    padding: 10px;
  }

  .navbar {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }

  .navbar-left,
  .navbar-right {
    justify-content: center;
    margin-bottom: 10px;
  }

  .navbar-name {
    margin-left: 0;
  }

  .navbar-home {
    margin-right: 20px;
  }

  .header-content {
    align-items: center;
  }

  .name-title,
  .job-title,
  .contact-me-button {
    margin-left: 0;
    text-align: center;
  }

  .projects-title,
  .projects-heading {
    margin-left: 20px;
  }

  .projects {
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  .navbar-home {
    margin-right: 10px;
    font-size: 16px;
  }

  .name-title {
    font-size: 48px;
  }

  .job-title {
    font-size: 32px;
  }

  .contact-me-button {
    padding: 12px 18px;
    font-size: 16px;
  }

  .projects-title {
    font-size: 24px;
  }

  .projects-heading {
    font-size: 1.2em;
  }
}
.modal-open .top-section,
.modal-open .bottom-section,
.modal-open .footer {
  filter: blur(5px);
}
