.beakd-App {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.beakd-header {
    text-align: center;
    padding: 20px;
    background-color: rgb(239, 239, 239);
}

.beakd-title {
    font-size: 48px;
    font-weight: bold;
    color: #B6330A;
    margin: 0;
}

.beakd-subtitle {
    font-size: 18px;
    color: #2A342C;
    margin: 10px auto 0;
    max-width: 600px;
    line-height: 1.5;
    margin-top: 20px;
}

.beakd-top-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(239, 239, 239);
    padding-bottom: 145px;
}

.beakd-navbar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #2A342C;
    color: #fff;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.beakd-navbar.open {
    left: 0;
}

.beakd-navbar-toggle {
    position: absolute;
    top: 10px;
    right: -40px;
    background-color: #2A342C;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}

.beakd-navbar-content {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.beakd-navbar-left {
    padding: 20px;
}

.beakd-navbar-right {
    margin-top: auto;
    padding: 20px;
}

.beakd-navbar-name {
    font-size: 24px;
    font-weight: bold;
    color: #B6330A;
}

.beakd-navbar-home {
    color: #EFF2E9;
    text-decoration: none;
}

.beakd-footer {
    background-color: #2A342C;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

.beakd-footer a {
    color: #EFF2E9;
    text-decoration: none;
}

.beakd-footer p {
    margin: 5px 0;
    color: #EFF2E9;
}

.beakd-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.beakd-carousel-left,
.beakd-carousel-right {
    width: 90%;
    max-width: 300px;
    margin: 0 10px;
}

.beakd-carousel img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.beakd-slide-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    margin-top: 10px;
}

.beakd-image-text {
    background-color: rgba(205, 204, 204, 0.278);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: rgb(10, 6, 6);
}

.beakd-waitlist-button {
    background-color: #B6330A;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.beakd-waitlist-button:hover {
    background-color: #d79b89;
    color:#2A342C;
}


@media (min-width: 768px) {
    .beakd-carousel {
        flex-direction: row;
    }

    .beakd-carousel-left,
    .beakd-carousel-right {
        width: 45%;
    }

    .beakd-slide-text {
        margin-top: 0;
    }
}

.beakd-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .beakd-modal-content {
    background: rgb(239, 239, 239);
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    position: relative;
  }
  
  .beakd-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .beakd-modal-content h2 {
    margin-bottom: 15px;
  }
  
  .beakd-modal-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .beakd-modal-content input {
    width: 80%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .beakd-modal-content button {
    padding: 10px 20px;
    background-color: #2A342C;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .beakd-modal-content button:hover {
    background-color: #2A342C;
    color: #f7f7f7;
    font-weight: bold;
  }

  .beakd-modal-title{
    color:#2A342C
  }
  .beakd-modal-description {
    color: #2A342C;
    margin-bottom: 10px;
  }

  